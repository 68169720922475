<template>
	<v-sheet class="opportunity-main-listing">
		<v-layout>
			<v-flex class="py-0">
				<div class="">
					<v-select
						v-model="status"
						class="listing-select"
						hide-details
						:disabled="pageLoading"
						:items="listingStatus"
						style="width: 250px !important"
						solo
						@change="statusFilterRows($event)"
						:menu-props="{ offsetY: true, contentClass: 'rounded-lg' }"
					>
						<template v-slot:item="{ item }">
							<v-list-item-action>
								<v-avatar size="26" :color="item.status_color" :text-color="item.textcolor">
									<span class="white--text">{{ item.status_count }}</span>
								</v-avatar>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title v-text="item.text"></v-list-item-title>
								<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
							</v-list-item-content>
						</template>
					</v-select>
				</div>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex class="py-0 my-auto d-flex text-right listing-right justify-content-end">
				<template>
					<v-btn
						v-if="getPermission('opportunity:create')"
						depressed
						tile
						color="red darken-4"
						class="text-white"
						@click="CreateOpportunityDrawer()"
						:disabled="pageLoading"
					>
						<v-icon left> mdi-plus </v-icon>
						Create
					</v-btn>
					<!-- <v-btn depressed :disabled="pageLoading" color="blue darken-4" class="text-white" tile>
						<v-icon>mdi-cog</v-icon>
					</v-btn> -->

					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<v-list class="py-0">
							<template v-for="(item, i) in listingAction">
								<v-list-item link :key="i" v-on:click="referess">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('member')"
						>
							<template v-for="cols in draggableThead">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										v-on:change="updateTableVisiblity('opportunity', cols.key)"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
				</template>
			</v-flex>
		</v-layout>
		<v-layout>
			<v-flex class="ml-2 d-flex justify-content-start pr-1">
				<div class="mr-2 fw-600 my-auto">Filter By</div>
				<div class="flex-grow-custom">
					<v-autocomplete
						v-model="filterProductType"
						clearable
						:items="productList"
						v-on:click:clear="defaultFilter = {}"
						item-text="text"
						item-value="id"
						:menu-props="{ bottom: true, offsetY: true }"
						prepend-inner-icon="mdi-tag"
						outlined
						hide-details
						class="filterSelect"
						placeholder="Product Type"
						content-class="filterSelectContent"
						v-on:change="topBarFilter($event, 'product_type')"
					>
					</v-autocomplete>
				</div>
				<div class="flex-grow-custom ml-3">
					<v-autocomplete
						v-model="opportunity"
						clearable
						:items="opportunityList"
						v-on:click:clear="defaultFilter = {}"
						item-text="text"
						item-value="value"
						:menu-props="{ bottom: true, offsetY: true }"
						prepend-inner-icon="mdi-circle"
						:color="filterColorMange(opportunity)"
						:class="filterColorMangeIcon(opportunity)"
						outlined
						placeholder="Opportunity"
						hide-details
						class="filterSelect"
						content-class="filterSelectContent"
						v-on:change="topBarFilter($event, 'opportunity')"
					>
						<template v-slot:item="data">
							<template>
								<v-list-item-icon class="mr-0 my-1">
									<v-icon
										class="mdi-18px"
										:color="
											data.item.value == 'low-chance'
												? 'cyan'
												: data.item.value == '50-50'
												? 'green'
												: data.item.value == 'high-chance'
												? 'orange'
												: data.item.value == 'none'
												? 'blue'
												: 'red'
										"
										>mdi-circle</v-icon
									>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title v-html="data.item.text"></v-list-item-title>
								</v-list-item-content>
							</template>
						</template>
					</v-autocomplete>
				</div>

				<div
					class="flex-grow-custom ml-3"
					v-if="(currentUser && currentUser.role == 1) || currentUser.role == 4"
				>
					<!--  {{ user_filter }}
				<pre>{{ users }}</pre> -->
					<v-autocomplete
						v-model="user_filter"
						:items="users"
						clearable
						v-on:click:clear="defaultFilter = {}"
						:menu-props="{ bottom: true, offsetY: true }"
						outlined
						hide-details
						item-text="display_name"
						item-value="id"
						class="filterSelect"
						placeholder="Users"
						content-class="filterSelectContent rounded-lg"
						v-on:change="topBarFilter($event, 'users')"
					>
						<template #selection="data">
							<v-chip color="#dadfff" small v-bind="data.attrs" :input-value="data.selected">
								<v-avatar left v-if="data.item.profile_img">
									<v-img v-if="data.item.profile_img" :src="data.item.profile_img"></v-img>
									<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
								</v-avatar>
								<span class="fw-600 text-uppercase">{{ data.item.display_name }}</span>
							</v-chip>
						</template>
						<template #item="data">
							<div class="py-1 d-flex align-center">
								<v-avatar size="36" class="mr-2">
									<v-img v-if="data.item.profile_img" :src="data.item.profile_img" />
									<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
								</v-avatar>
								<div>
									<div class="fw-600 text-uppercase">{{ data.item.display_name }}</div>
									<div class="fw-500 text-muted">{{ data.item.email }}</div>
								</div>
							</div>
						</template>
					</v-autocomplete>
				</div>
				<div class="flex-grow-custom ml-3">
					<v-autocomplete
						v-model="filter_source"
						v-on:click:clear="defaultFilter = {}"
						:items="sourceList"
						clearable
						:menu-props="{ bottom: true, offsetY: true }"
						outlined
						hide-details
						item-text="text"
						item-value="value"
						class="filterSelect"
						placeholder="Source"
						content-class="filterSelectContent"
						v-on:change="topBarFilter($event, 'source')"
					>
					</v-autocomplete>
				</div>
				<div class="flex-grow-custom ml-3">
					<DateRangePicker
						:id="+new Date()"
						v-on:click:clear="clearFliterDate"
						hide-details
						v-on:clearDates="dateRangeClear"
						:clear="date_status"
						hide-top-margin
						clearable
						placeholder="Reminder"
						v-model="filter_reminder_date"
						v-on:change="
							($event) => {
								Array.isArray($event) && $event.length > 1 && topBarFilter($event, 'date');
							}
						"
					></DateRangePicker>
					<!-- :disablePastFrom="Array.isArray(filter_reminder_date) ? filter_reminder_date[0] : ''"-->
				</div>
				<div class="">
					<v-btn
						depressed
						color="white"
						class="red darken-4 ml-2"
						tile
						text
						small
						style="height: 30px; min-width: 0px !important"
						v-on:click="clearFliter"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
			</v-flex>
		</v-layout>
		<v-layout
			class="page-summary"
			style="display: grid; grid-template-columns: repeat(8, 1fr)"
			:class="summary_show ? 'show mt-2' : ''"
		>
			<template>
				<template v-for="(statusData, key) in listingStatus">
					<!-- summary-details bg-light-red -->
					<PageSummeryBox
						:key="key"
						:color="statusData.status_color"
						:text="statusData.text"
						:count="statusData.status_count"
						:isActive="statusData.value === status"
						@click="statusData.value != status && statusFilterRows(statusData.value)"
						:class="{ active: statusData.value === status }"
					/>
				</template>
			</template>
		</v-layout>
		<OpportunityListing v-on:createSuccess="filterRows"></OpportunityListing>

		<CreateOpportunityDrawer
			v-if="create_opportunity"
			v-on:createSuccess="getListing()"
			:drawer="create_opportunity"
			title-status="Create"
			v-on:close="create_opportunity = false"
		></CreateOpportunityDrawer>
		<div style="position: fixed; bottom: 100px; right: 20px; z-index: 9">
			<div class="position-relative">
				<div class="call-btn-underlay" style="background-color: rgb(243, 165, 165); z-index: 9"></div>
				<v-btn
					fab
					dark
					large
					v-if="getPermission('opportunity:create')"
					color="red darken-4"
					@click="CreateOpportunityDrawer()"
					:disabled="pageLoading"
				>
					<v-icon dark>mdi-plus </v-icon>
				</v-btn>
			</div>
		</div>
	</v-sheet>
</template>
<script>
import OpportunityListing from "@/view/module/opportunity/OpportunityListing";
import { toSafeInteger } from "lodash";
import ListingMixin from "@/core/mixins/listing.mixin";
import CreateOpportunityDrawer from "@/view/pages/leads/create/CreateOpportunityDrawer";
import { GET, QUERY } from "@/core/services/store/request.module";
import { TaskEventBus } from "@/core/lib/task.form.lib";
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import { SET_ERROR } from "@/core/services/store/common.module";
import SettingsMixin from "@/core/mixins/settings.mixin";
import objectPath from "object-path";

import {
	SET_TBODY,
	SET_SHOWING_STRING,
	SET_THEAD,
	SET_FILTER,
	SET_STATUS,
	SET_TOTAL_PAGE,
	SET_CURRENT_PAGE,
	SET_BULK_ACTION,
	SET_ACTION,
} from "@/core/services/store/listing.module";
export default {
	name: "opportunity-listing",
	mixins: [ListingMixin, SettingsMixin],
	data() {
		return {
			pageLoading: false,
			typeId: 4,
			summary_show: true,
			viewType: true,
			labelItems: ["High", "Medium", "Low"],
			filter_label: "High",
			create_opportunity: false,
			pageTitle: "Opportunity",
			pageBreadcrumbs: [{ text: "opportunity", disabled: true }],
			endpoint: "opportunity",
			defaultFilter: {},
			settingsEndpoint: "task-setting",
			addvanceFilterAarry: [],
			statusFilterData: [],
			filterProductType: [],
			users: [],
			date_status: Number(new Date()),
			editTask: {},
			attendieList: {},
			drawerFilter: false,
			priorityList: [],
			status: "all",
			productList: [],
			// listingStatus: [
			// 	{
			// 		id: 1,
			// 		text: "All Opportunity",
			// 		value: "all",
			// 		type: 1,
			// 		provider_type: null,
			// 		seo: 0,
			// 		hosting: 0,
			// 		domain: 0,
			// 		status_color: "blue",
			// 		count: 0,
			// 		child_name: null,
			// 		cost: null,
			// 		activation_date: null,
			// 		renewal_basis: null,
			// 		renewal_date: null,
			// 		status_count: 4,
			// 	},
			// 	{
			// 		id: 2,
			// 		text: "Not Contacted",
			// 		value: "not-contacted",
			// 		type: 1,
			// 		provider_type: null,
			// 		seo: 0,
			// 		hosting: 0,
			// 		domain: 0,
			// 		status_color: "purple",
			// 		count: 0,
			// 		child_name: null,
			// 		cost: null,
			// 		activation_date: null,
			// 		renewal_basis: null,
			// 		renewal_date: null,
			// 		status_count: 3,
			// 	},
			// 	{
			// 		id: 3,
			// 		text: "Follow UP",
			// 		value: "follow-up",
			// 		type: 1,
			// 		provider_type: null,
			// 		seo: 0,
			// 		hosting: 0,
			// 		domain: 0,
			// 		status_color: "orange",
			// 		count: 0,
			// 		child_name: null,
			// 		cost: null,
			// 		activation_date: null,
			// 		renewal_basis: null,
			// 		renewal_date: null,
			// 		status_count: 0,
			// 	},
			// 	{
			// 		id: 4,
			// 		text: "Meeting",
			// 		value: "meeting",
			// 		type: 1,
			// 		provider_type: null,
			// 		seo: 0,
			// 		hosting: 0,
			// 		domain: 0,
			// 		status_color: "pink",
			// 		count: 0,
			// 		child_name: null,
			// 		cost: null,
			// 		activation_date: null,
			// 		renewal_basis: null,
			// 		renewal_date: null,
			// 		status_count: 0,
			// 	},
			// 	{
			// 		id: 5,
			// 		text: "Quotation",
			// 		value: "quotation",
			// 		type: 1,
			// 		provider_type: null,
			// 		seo: 0,
			// 		hosting: 0,
			// 		domain: 0,
			// 		status_color: "cyan",
			// 		count: 0,
			// 		child_name: null,
			// 		cost: null,
			// 		activation_date: null,
			// 		renewal_basis: null,
			// 		renewal_date: null,
			// 		status_count: 1,
			// 	},
			// 	{
			// 		id: 6,
			// 		text: "Rejected",
			// 		value: "reject",
			// 		type: 1,
			// 		provider_type: null,
			// 		seo: 0,
			// 		hosting: 0,
			// 		domain: 0,
			// 		status_color: "red",
			// 		count: 0,
			// 		child_name: null,
			// 		cost: null,
			// 		activation_date: null,
			// 		renewal_basis: null,
			// 		renewal_date: null,
			// 		status_count: 2,
			// 	},
			// 	{
			// 		id: 7,
			// 		text: "Accepted",
			// 		value: "accept",
			// 		type: 1,
			// 		provider_type: null,
			// 		seo: 0,
			// 		hosting: 0,
			// 		domain: 0,
			// 		status_color: "green",
			// 		count: 0,
			// 		child_name: null,
			// 		cost: null,
			// 		activation_date: null,
			// 		renewal_basis: null,
			// 		renewal_date: null,
			// 		status_count: 4,
			// 	},
			// ],
			sourceList: [
				// { text: "All", type: 2, value: "" },
				// { text: "Web Enquiry", value: "web-enquiry", type: 2 },
				// { text: "Web Chat", value: "web-chat", type: 2 },
				// { text: "Email Marketer", value: "email-marketer", type: 2 },
				// { text: "Tele Call", value: "tele-call", type: 2 },
				// { text: "Google Adwords", value: "google-adwords", type: 2 },
				// { text: "FSM/AMS(Brochure Lead Form)", value: "fsm-ams", type: 2 },
				// { text: "Facebook", value: "facebook", type: 2 },
				// { text: "Referral", value: "referral", type: 2 },
				// { text: "Intern", value: "intern", type: 2 },
				// { text: "None", value: "none", type: 2 },
			],
			opportunityList: [
				{ text: "No Opportunity", value: null },
				{ text: "All", type: 3, value: "" },
				{ text: "50 50", value: "50-50", type: 3 },
				{ text: "High Chance", value: "high-chance", type: 3 },
				{ text: "None", value: "none", type: 3 },
				{ text: "Low Chance", value: "low-chance", type: 3 },
			],
		};
	},
	components: {
		CreateOpportunityDrawer,
		OpportunityListing,
		PageSummeryBox: () => import("@/view/components/PageSummeryBox"),
		DateRangePicker: () => import("@/view/components/DateRangePickerV2"),
	},
	methods: {
		getSetting() {
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `lead-setting`,
				})
				.then((data) => {
					if (data) {
						this.productList = data?.product_type;
						this.sourceList = data?.source;
					}
					// this.$emit("success", true);
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		allFiltersSetting(filter) {
			this.$router.push({
				name: "opportunity",
				query: {
					date: filter.date,
					product_type: filter.product_type,
					opportunity: filter.opportunity,
					user_filter: filter.user_filter,
					source: filter.source,
					status: filter.status,
					t: new Date().getTime(),
					search: this.listingSearch || undefined,
				},
			});
		},
		clearFliterDate() {
			this.filter_reminder_date = null;
			this.$router.replace({ name: "leads", query: { ...this.$route.query, date: [] } });
			this.topBarFilter();
		},
		clearFliter() {},
		dateRangeClear() {
			this.clearDate = false;
		},
		filterColorMange(label) {
			if (label == "50-50") {
				return "green";
			} else if (label == "high-chance") {
				return "orange";
			} else if (label == "none") {
				return "blue";
			} else if (label == "low-chance") {
				return "cyan";
			} else {
				return "red";
			}
		},
		filterColorMangeIcon(label) {
			if (label == "50-50") {
				return "green-icon";
			} else if (label == "high-chance") {
				return "orange-icon";
			} else if (label == "none") {
				return "blue-icon";
			} else if (label == "low-chance") {
				return "cyan-icon";
			} else {
				return "red-icon";
			}
		},
		referess() {
			window.location.reload();
		},
		dataFilter(value) {
			this.addvanceFilterAarry = value;
		},
		open_drawer(id) {
			this.create_opportunity = true;
			this.$store
				.dispatch(GET, { url: `single-task/${id}` })
				.then((data) => {
					this.editTask = data.tasks;
					this.attendieList = data.attendie[0];
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		viewTypedata() {
			this.viewType = !this.viewType;
			this.$router.push({
				name: "opportunity",
				query: {
					t: new Date().getTime(),
					viewType: this.viewType,
				},
			});
		},

		allFiltersStatus(value) {
			this.$router.push({
				name: "opportunity",
				query: {
					status: value,
					user_filter: this.user_filter,
					t: new Date().getTime(),
				},
			});
		},
		CreateOpportunityDrawer() {
			this.editTask = {};
			this.attendieList = {};
			this.create_opportunity = true;
		},
		topBarFilter() {
			//this.$route.query.page ? this.$route.query.page : null,
			let filter = {
				date:
					this.filter_reminder_date && this.filter_reminder_date.length > 1
						? this.filter_reminder_date
						: this.$route.query.date,
				product_type: this.filterProductType ? this.filterProductType : null,
				opportunity: this.opportunity || null,
				user_filter: this.user_filter || null,
				source: this.filter_source || null,
				status: this.status,
				page: 1,
				search: this.listingSearch || undefined,
			};

			if (Array.isArray(this.filter_reminder_date) && this.filter_reminder_date.length > 1) {
				//filter?.date[0] && filter?.date[1]

				if (moment(filter.date[0]).isAfter(filter.date[1])) {
					this.$store.commit(SET_ERROR, [
						{ model: true, message: `Start Date can not be less than End Date` },
					]);
					//this.filter_reminder_date =  null;
					return false;
				}
			}

			/* 	const filterKey = type; */
			this.allFiltersSetting(filter);
			if (!this.viewType) {
				this.pageLoading = true;
				this.$store
					.dispatch(QUERY, {
						url: `opportunity`,
						data: filter /* {
						status: "all",
						[filterKey]: value,
					}, */,
					})
					.then((data) => {
						this.$store.commit(SET_ACTION, data.action);
						this.$store.commit(SET_BULK_ACTION, data.bulk_action);
						this.$store.commit(SET_CURRENT_PAGE, 1);
						this.$store.commit(SET_TOTAL_PAGE, data.total_page);
						this.$store.commit(SET_SHOWING_STRING, data.showing_string);
						this.$store.commit(SET_STATUS, data.status);
						this.$store.commit(SET_FILTER, data.filter);
						this.$store.commit(SET_TBODY, data.tbody);
						this.$store.commit(SET_THEAD, data.thead);
					})
					.catch((error) => {
						console.log({
							error,
						});
					})
					.finally(() => {
						this.pageLoading = false;
					});
			}
		},
		routeSetting() {
			this.$router.push({
				name: "setting",
				query: {
					tab: "opportunity",
				},
			});
		},

		statusFilterRows(value) {
			// this.status = this.status === value ? null : value;
			this.status = value;

			this.pageLoading = true;
			this.allFiltersStatus(value);
			this.$store
				.dispatch(QUERY, {
					url: `opportunity`,
					data: {
						status: value,
						user_filter: this.user_filter,
						date:
							this.filter_reminder_date && this.filter_reminder_date.length > 0
								? this.filter_reminder_date
								: this.$route.query.date,
						product_type: this.productType ? this.productType : null,
						opportunity: this.opportunity || null,
						// user_filter: this.user_filter || null,
						source: this.filter_source || null,
						page: 1,
						search: this.listingSearch || undefined,
					},
				})
				.then((data) => {
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, 1);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
					/* this.addvanceFilterAarry = []; */
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	computed: {
		...mapGetters(["currentUser", "moduleSettings"]),
	},
	watch: {
		moduleSettings: {
			immediate: true,
			handler(settings) {
				if (Object.keys(settings).length) {
					this.filterProductType = settings.product_type;
					this.filterProductType?.unshift({
						text: "All",
						type: 4,
						value: "",
					});
					this.filterOpportunity = settings.opportunity;

					this.filterOpportunity?.unshift({
						text: "All",
						type: 3,
						value: "",
					});
					this.users = settings.users;
					this.source = settings.source;
					this.source?.unshift({
						text: "All",
						type: 2,
						value: "",
					});
				}
			},
		},
	},
	beforeMount() {
		this.user_filter = toSafeInteger(this.$route.query.user_filter);
		if ((this.currentUser.role == 1 || this.currentUser.role == 4) && !this.user_filter) {
			this.user_filter = toSafeInteger(this.$route.query.user_filter);
		}
		this.defaultFilter = {
			user_filter: this.user_filter ? this.user_filter : null,
		};
	},
	mounted() {
		// this.getTaskStatus();
		this.user_filter = toSafeInteger(this.$route.query.user_filter);

		this.viewType = this.$route.query.viewType;
		let filterQuery = this.$route.query;
		this.status = objectPath.get(filterQuery, "status") || "all";

		this.$router.push({
			name: "opportunity",
			query: {
				...filterQuery,
				viewType: this.viewType,
				t: new Date().getTime(),
			},
		});
		TaskEventBus.$on("refresh", () => {
			this.getListing();
		});
		this.getSetting();
		/* this.topBarFilter(); */
	},
};
</script>
<style scoped>
.bt-table tbody tr:nth-child(even) {
	background-color: rgb(237, 242, 251);
}
</style>
